import {
    UPLOAD_FILE,
    UPLOAD_FILE_SUCCESS,
    GET_STRUCTURE_SUCCESS
} from '../actions';

const initialState = {   data: {},   loading: true,   error: '', upload_data: {path:'', ioType: '' } };

export default function reduxSagaReducer(state = initialState, action) {

    switch (action.type) {

        case UPLOAD_FILE: {
            return {
                ...state,
                loading: true,
                error:''
            };
        }

        case UPLOAD_FILE_SUCCESS: {
            return {
                ...state,
                loading: false,
                upload_data: action.data,
            };
        }

        case GET_STRUCTURE_SUCCESS: {
            return {
                ...state,
                loading: false,
                structure_data: action.data,
            };
        }

        default: {
            return state;
        }

    }

}
