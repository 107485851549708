/* SETTINGS */
export const CHANGE_LOCALE = "CHANGE_LOCALE";
export const CHECK_TIMEOUT = "CHECK_TIMEOUT";

/* AUTH */
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";
export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_ERROR = "REGISTER_USER_ERROR";
export const LOGOUT_USER = "LOGOUT_USER";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";
export const USER_CONFIRM = "USER_CONFIRM";
export const USER_CONFIRM_SUCCESS = "USER_CONFIRM_SUCCESS";
export const USER_CONFIRM_ERROR = "USER_CONFIRM_ERROR";
export const USER_CHANGE_PASSWORD = "USER_CHANGE_PASSWORD";
export const USER_CHANGE_PASSWORD_SUCCESS = "USER_CHANGE_PASSWORD_SUCCESS";
export const USER_CHANGE_PASSWORD_ERROR = "USER_CHANGE_PASSWORD_ERROR";
/* Language */
export const LOAD_LANGUAGE_LOADING = 'LOAD_LANGUAGE_LOADING';
export const LOAD_LANGUAGE_SUCCESS = 'LOAD_LANGUAGE_SUCCESS';
export const LOAD_LANGUAGE_ERROR = 'LOAD_LANGUAGE_ERROR';

/* Translations */
export const LOAD_TRANSLATIONS_LOADING = 'LOAD_TRANSLATIONS_LOADING';
export const LOAD_TRANSLATIONS_SUCCESS = 'LOAD_TRANSLATIONS_SUCCESS';
export const LOAD_TRANSLATIONS_ERROR = 'LOAD_TRANSLATIONS_ERROR';
export const EDIT_TRANSLATION_START = 'EDIT_TRANSLATION_START';
export const EDIT_TRANSLATION_CANCEL = 'EDIT_TRANSLATION_CANCEL';
export const EDIT_TRANSLATION_SUBMIT = 'EDIT_TRANSLATION_SUBMIT';
export const EDIT_TRANSLATION_SUBMIT_SUCCESS = 'EDIT_TRANSLATION_SUBMIT_SUCCESS';
export const EDIT_TRANSLATION_SUBMIT_ERROR = 'EDIT_TRANSLATION_SUBMIT_ERROR';
export const DELETE_TRANSLATION = 'DELETE_TRANSLATION';
export const DELETE_TRANSLATION_ERROR = 'DELETE_TRANSLATION_ERROR';
export const CLEAR_TRANSLATIONS_DATA = 'CLEAR_TRANSLATIONS_DATA';

/* Flow */
export const LOAD_FLOWS_LOADING = 'LOAD_FLOWS_LOADING';
export const LOAD_FLOWS_SUCCESS = 'LOAD_FLOWS_SUCCESS';


/*Load Transport*/
export const LOAD_TRANSPORT_LOADING = 'LOAD_TRANSPORT_LOADING';
export const LOAD_TRANSPORT_SUCCESS = 'LOAD_TRANSPORT_SUCCESS';
export const LOAD_TRANSPORT_ERROR = 'LOAD_TRANSPORT_ERROR';
/*Post Transport*/
export const POST_TRANSPORT_LOADING = 'POST_TRANSPORT_LOADING';
export const POST_TRANSPORT_SUCCESS = 'POST_TRANSPORT_SUCCESS';
export const POST_TRANSPORT_ERROR = 'POST_TRANSPORT_ERROR';
/*Test Transport*/
export const TEST_TRANSPORT_LOADING = 'TEST_TRANSPORT_LOADING';
export const TEST_TRANSPORT_SUCCESS = 'TEST_TRANSPORT_SUCCESS';
export const TEST_TRANSPORT_ERROR = 'TEST_TRANSPORT_ERROR';
/*ioType*/
export const LOAD_IOTYPE_LOADING = 'LOAD_IOTYPE_LOADING';
export const LOAD_IOTYPE_SUCCESS = 'LOAD_IOTYPE_SUCCESS';
export const LOAD_IOTYPE_ERROR = 'LOAD_IOTYPE_ERROR';

/*Load Transport Identity*/
export const LOAD_TRANSPORT_IDENTITY_LOADING = 'LOAD_TRANSPORT_IDENTITY_LOADING';
export const LOAD_TRANSPORT_IDENTITY_SUCCESS = 'LOAD_TRANSPORT_IDENTITY_SUCCESS';
export const LOAD_TRANSPORT_IDENTITY_ERROR = 'LOAD_TRANSPORT_IDENTITY_ERROR';
export const LOAD_TRANSPORT_IDENTITY_BY_ID_SUCCESS = 'LOAD_TRANSPORT_IDENTITY_BY_ID_SUCCESS';
export const DELETE_TRANSPORT_IDENTITY = 'DELETE_TRANSPORT_IDENTITY';


export const DELETE_FLOW = 'DELETE_FLOW';

/*edit transport identity*/
export const CLEAR_TRANSPORT_IDENTITY = 'CLEAR_TRANSPORT_IDENTITY';
export const NEW_TRANSPORT_IDENTITY_DESTINATION_FIELD = 'NEW_TRANSPORT_IDENTITY_DESTINATION_FIELD';

/*Load Transport Identity By Id*/
export const LOAD_TRANSPORT_IDENTITY_BY_ID_LOADING = 'LOAD_TRANSPORT_IDENTITY_BY_ID_LOADING';
export const LOAD_TRANSPORT_IDENTITY_FROM_SUCCESS = 'LOAD_TRANSPORT_IDENTITY_FROM_SUCCESS';
export const LOAD_TRANSPORT_IDENTITY_TO_SUCCESS = 'LOAD_TRANSPORT_IDENTITY_TO_SUCCESS';
export const LOAD_TRANSPORT_IDENTITY_HEADERS_LOADING = 'LOAD_TRANSPORT_IDENTITY_HEADERS_LOADING';

/*Transport Types*/
export const DELETE_TRANSPORT = 'DELETE_TRANSPORT';
export const LOAD_TRANSPORT_TYPE_LOADING = 'LOAD_TRANSPORT_TYPE_LOADING';
export const LOAD_TRANSPORT_TYPE_SUCCESS = 'LOAD_TRANSPORT_TYPE_SUCCESS';
export const LOAD_TRANSPORT_TYPE_ERROR = 'LOAD_TRANSPORT_TYPE_ERROR';
export const CLEAR_TRANSPORT_DATA = 'CLEAR_TRANSPORT_DATA';
export const POST_TRANSPORT_TYPE_LOADING = 'POST_TRANSPORT_TYPE_LOADING';

/* Upload File */
export const UPLOAD_FILE = 'UPLOAD_FILE';
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';

/* MENU */
export const MENU_SET_CLASSNAMES = "MENU_SET_CLASSNAMES";
export const MENU_CONTAINER_ADD_CLASSNAME = "MENU_CONTAINER_ADD_CLASSNAME";
export const MENU_CLICK_MOBILE_MENU = "MENU_CLICK_MOBILE_MENU";
export const MENU_CHANGE_DEFAULT_CLASSES = "MENU_CHANGE_DEFAULT_CLASSES";
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS =
  "MENU_CHANGE_HAS_SUB_ITEM_STATUS";

/* Company Details */
export const LOAD_COMPANY_DETAILS_LOADING = 'LOAD_COMPANY_DETAILS_LOADING';
export const LOAD_COMPANY_DETAILS_SUCCESS = 'LOAD_COMPANY_DETAILS_SUCCESS';
export const LOAD_COMPANY_DETAILS_ERROR = 'LOAD_COMPANY_DETAILSE_ERROR';
/* Post Company Details */
export const POST_COMPANY_DETAILS = 'POST_COMPANY_DETAILS';
export const POST_COMPANY_DETAILS_SUCCESS = 'POST_COMPANY_DETAILS_SUCCESS';
export const POST_COMPANY_DETAILS_ERROR = 'POST_COMPANY_DETAILS_ERROR';
/* Post Company Details */
export const CHECK_VAT_NUMBER = 'CHECK_VAT_NUMBER';
export const CHECK_VAT_NUMBER_SUCCESS = 'CHECK_VAT_NUMBER_SUCCESS';
export const CHECK_VAT_NUMBER_ERROR = 'CHECK_VAT_NUMBER_ERROR';
/* Account Details */
export const LOAD_ACCOUNT_DETAILS_LOADING = 'LOAD_ACCOUNT_DETAILS_LOADING';
export const LOAD_ACCOUNT_DETAILS_SUCCESS = 'LOAD_ACCOUNT_DETAILS_SUCCESS';
export const LOAD_ACCOUNT_DETAILS_ERROR = 'LOAD_ACCOUNT_DETAILSE_ERROR';
/* Post Account Details */
export const POST_ACCOUNT_DETAILS = 'POST_ACCOUNT_DETAILS';
export const POST_ACCOUNT_DETAILS_SUCCESS = 'POST_ACCOUNT_DETAILS_SUCCESS';
export const POST_ACCOUNT_DETAILS_ERROR = 'POST_ACCOUNT_DETAILS_ERROR';

export const DELETE_DESTINATION_LIST_ITEM = 'DELETE_DESTINATION_LIST_ITEM';
export const MOVE_LIST_ITEM_UP = 'MOVE_LIST_ITEM_UP';
export const MOVE_LIST_ITEM_DOWN = 'MOVE_LIST_ITEM_DOWN';


export const LOAD_STRUCTURE_LOADING = 'LOAD_STRUCTURE_LOADING';
export const LOAD_STRUCTURE_SUCCESS = 'LOAD_STRUCTURE_SUCCESS';
export const LOAD_STRUCTURE_ERROR = 'LOAD_STRUCTURE_ERROR';
export const GET_STRUCTURE_SUCCESS = 'GET_STRUCTURE_SUCCESS';

export const SAVE_FLOW = 'SAVE_FLOW';
export const LOAD_FLOW_LOADING = 'LOAD_FLOW_LOADING';
export const LOAD_FLOW_BY_ID_SUCCESS = 'LOAD_FLOW_BY_ID_SUCCESS';


export const SET_FLOW_HEADERS = 'SET_FLOW_HEADERS';

export * from "./menu/actions";
export * from "./settings/actions";
export * from "./auth/actions";
