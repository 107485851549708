import { put, takeLatest } from 'redux-saga/effects';

import {
    LOAD_COMPANY_DETAILS_LOADING,
    LOAD_COMPANY_DETAILS_SUCCESS,
    LOAD_COMPANY_DETAILS_ERROR,
    POST_COMPANY_DETAILS,
    POST_COMPANY_DETAILS_SUCCESS,
    POST_COMPANY_DETAILS_ERROR,
    CHECK_VAT_NUMBER,
    CHECK_VAT_NUMBER_SUCCESS,
    CHECK_VAT_NUMBER_ERROR,
    LOAD_ACCOUNT_DETAILS_LOADING,
    LOAD_ACCOUNT_DETAILS_SUCCESS,
    LOAD_ACCOUNT_DETAILS_ERROR,
    POST_ACCOUNT_DETAILS,
    POST_ACCOUNT_DETAILS_SUCCESS,
    POST_ACCOUNT_DETAILS_ERROR,
} from '../actions';
import {NotificationManager} from "../../components/common/react-notifications";
import IntlMessages from "../../helpers/IntlMessages";
import React from "react";
import {companyDetails, updateCompanyDetails, checkVatNr, accountDetails, updateAccountDetails } from "../../helpers/Api";


async function fetchAsync(func, data) {
    const response = await func(data);
    if (response.status === 200 && response) {
        return await response.data;
    }
    throw new Error(response.message);
}

const loadCompanyDetailsAsync = async (data) =>
    await companyDetails(data)
        .then(company_details => {
            return company_details.data;
        })
        .catch(error => error);

const postCompanyDetailsAsync = async (data) =>
    await updateCompanyDetails(data)
        .then(company_details => {
            return company_details.data;
        })
        .catch(error => error);

const checkVatNumberAsync = async (data) =>
    await checkVatNr(data)
        .then(vat_number => {
            return vat_number.data;
        })
        .catch(error => error);

const loadAccountDetailsAsync = async (data) =>
    await accountDetails(data)
        .then(account_details => {
            return account_details.data;
        })
        .catch(error => error);


const postAccountDetailsAsync = async (data) =>
    await updateAccountDetails(data)
        .then(account_details => {
            return account_details.data;
        })
        .catch(error => error);


function* loadCompanyDetails( {payload} ) {

    try {
        const company_details = yield fetchAsync(loadCompanyDetailsAsync, payload);

        yield put({type: LOAD_COMPANY_DETAILS_SUCCESS, data: company_details});
    }
    catch (e) {
        yield put({type: LOAD_COMPANY_DETAILS_ERROR, error: e.message});

        /*NotificationManager.error(
            <IntlMessages id={e.message} />,
            <IntlMessages id="error" />,
            3000,
            null,
            null,
            ''
        );*/
    }
}


function* postCompanyDetails( {payload} ) {
    try {
        const company_details = yield fetchAsync(postCompanyDetailsAsync, payload);

        yield put({type: POST_COMPANY_DETAILS_SUCCESS, data: company_details});

        NotificationManager.success(
            <IntlMessages id="post_company_details_success" />,
            <IntlMessages id="success" />,
            3000,
            null,
            null,
            ''
        );
    }
    catch (e) {
        yield put({type: POST_COMPANY_DETAILS_ERROR, error: e.message});

        NotificationManager.error(
            <IntlMessages id={e.message} />,
            <IntlMessages id="error" />,
            3000,
            null,
            null,
            ''
        );
    }
}


function* checkVatNumber( {payload} ) {
    try {
        const vat_number = yield fetchAsync(checkVatNumberAsync, payload);

        yield put({type: CHECK_VAT_NUMBER_SUCCESS, data: vat_number});

        NotificationManager.success(
            <IntlMessages id="check_vat_number_success" />,
            <IntlMessages id="success" />,
            3000,
            null,
            null,
            ''
        );
    }
    catch (e) {
        yield put({type: CHECK_VAT_NUMBER_ERROR, error: e.message});

        NotificationManager.error(
            <IntlMessages id={e.message} />,
            <IntlMessages id="error" />,
            3000,
            null,
            null,
            ''
        );
    }
}


function* loadAccountDetails( {payload} ) {

    try {
        const account_details = yield fetchAsync(loadAccountDetailsAsync, payload);
        yield put({type: LOAD_ACCOUNT_DETAILS_SUCCESS, data: account_details});
    }
    catch (e) {

        /*NotificationManager.error(
            <IntlMessages id={e.message} />,
            <IntlMessages id="error" />,
            3000,
            null,
            null,
            ''
        );*/
    }
}

//

function* postAccountDetails( {payload} ) {
    try {
        console.log(' postAccountDetails=' + payload);

        const account_details = yield fetchAsync(postAccountDetailsAsync, payload);

        yield put({type: POST_ACCOUNT_DETAILS_SUCCESS, data: account_details});

        NotificationManager.success(
            <IntlMessages id="post_account_details_success" />,
            <IntlMessages id="success" />,
            3000,
            null,
            null,
            ''
        );
    }
    catch (e) {
        yield put({type: POST_ACCOUNT_DETAILS_ERROR, error: e.message});

       /* NotificationManager.error(
            <IntlMessages id={e.message} />,
            <IntlMessages id="error" />,
            3000,
            null,
            null,
            ''
        );*/
    }
}


export function* rootSaga(  ) {
    yield takeLatest(LOAD_COMPANY_DETAILS_LOADING, loadCompanyDetails);
    yield takeLatest(POST_COMPANY_DETAILS, postCompanyDetails);
    yield takeLatest(CHECK_VAT_NUMBER, checkVatNumber);
    yield takeLatest(LOAD_ACCOUNT_DETAILS_LOADING, loadAccountDetails);
    yield takeLatest(POST_ACCOUNT_DETAILS, postAccountDetails);
}

export default rootSaga;
