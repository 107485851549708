import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from "redux-saga";
import reducers from './reducers';
import sagas from "./sagas";
import { createLogger } from "redux-logger";

const sagaMiddleware = createSagaMiddleware();
const reduxLogger = createLogger({ diff: true, duration: true, timeStamp: true });
//const middlewares = [sagaMiddleware, reduxLogger];
const middlewares = [sagaMiddleware];

export function configureStore(initialState) {
    let composer= null;

    if (process.env.NODE_ENV == 'development') {
        composer = compose(applyMiddleware(...middlewares), window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
    }
    else {
        composer = compose(applyMiddleware(...middlewares));
    }

    const store = createStore(
        reducers,
        initialState,
        composer
    );

    sagaMiddleware.run(sagas);

    if (module.hot) {
        module.hot.accept('./reducers', () => {
            const nextRootReducer = require('./reducers');
            store.replaceReducer(nextRootReducer);
        });
    }

    return store;
}
