import {
    LOAD_LANGUAGE_LOADING,
    LOAD_LANGUAGE_SUCCESS,
    LOAD_LANGUAGE_ERROR,
    LOAD_TRANSLATIONS_LOADING,
    LOAD_TRANSLATIONS_SUCCESS,
    LOAD_TRANSLATIONS_ERROR,
    EDIT_TRANSLATION_START,
    EDIT_TRANSLATION_CANCEL,
    EDIT_TRANSLATION_SUBMIT_SUCCESS,
    EDIT_TRANSLATION_SUBMIT_ERROR,
    EDIT_TRANSLATION_SUBMIT,
    DELETE_TRANSLATION_ERROR,
    DELETE_TRANSLATION,
    CLEAR_TRANSLATIONS_DATA
} from '../actions';

const initialState = {
    data: {}, loading: true, error: '',
    translations: null, translationsLoading: true,
};

export default function reduxSagaReducer(state = initialState, action) {

    switch (action.type) {

        case LOAD_LANGUAGE_LOADING: {

            return {

                ...state,

                loading: true,

                error: ''

            };

        }

        case LOAD_LANGUAGE_SUCCESS: {

            return {

                ...state,

                data: action.data,

                loading: false

            }

        }

        case LOAD_LANGUAGE_ERROR: {

            return {

                ...state,

                loading: false,

                error: action.error

            };

        }

        case LOAD_TRANSLATIONS_LOADING: {

            return {

                ...state,

                translationsLoading: true,

                error: ''

            };

        }

        case LOAD_TRANSLATIONS_SUCCESS: {

            return {

                ...state,

                translations: action.translations,

                translationsLoading: false

            }

        }

        case LOAD_TRANSLATIONS_ERROR: {

            return {

                ...state,

                translationsLoading: false,

                error: action.error

            };

        }

        case EDIT_TRANSLATION_START: {
            const language_code = action.payload.languageCode;
            const slug = action.payload.slug;
            const updatedTranslations = {...state.translations}
            updatedTranslations[language_code] = {...state.translations[language_code]}
            updatedTranslations[language_code].translations = {...state.translations[language_code].translations}
            updatedTranslations[language_code].translations[slug] = {
                ...state.translations[language_code].translations[slug],
                editing: true
            }

            return {

                ...state,

                translations: updatedTranslations,

            };

        }

        case EDIT_TRANSLATION_CANCEL: {
            const language_code = action.payload.languageCode;
            const slug = action.payload.slug;
            const updatedTranslations = {...state.translations}
            updatedTranslations[language_code] = {...state.translations[language_code]}
            updatedTranslations[language_code].translations = {...state.translations[language_code].translations}
            updatedTranslations[language_code].translations[slug] = {
                ...state.translations[language_code].translations[slug],
                editing: false
            }

            return {

                ...state,

                translations: updatedTranslations,
                
            };

        }

        case EDIT_TRANSLATION_SUBMIT: {
            const language_code = action.payload.languageCode;
            const slug = action.payload.slug;
            const updatedTranslations = {...state.translations}
            updatedTranslations[language_code] = {...state.translations[language_code]}
            updatedTranslations[language_code].translations = {...state.translations[language_code].translations}
            updatedTranslations[language_code].translations[slug] = {
                ...state.translations[language_code].translations[slug],
                editing: false
            }
            return {

                ...state,

                translations: updatedTranslations,
                
            };
        }

        case EDIT_TRANSLATION_SUBMIT_SUCCESS: {

            return {
                ...state,
            }
        }

        case EDIT_TRANSLATION_SUBMIT_ERROR: {

            return {

                ...state,

                error: action.error

            };

        }

        case DELETE_TRANSLATION_ERROR: {

            return {

                ...state,

                error: action.error

            };

        }

        case CLEAR_TRANSLATIONS_DATA: {

            return {
                ...state,
                translations: initialState.translations,
                translationsLoading: initialState.translationsLoading
            };

        }

        default: {

            return state;

        }

    }

}
