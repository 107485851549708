import {
  LOAD_LANGUAGE_LOADING,
  LOAD_TRANSLATIONS_LOADING,
  EDIT_TRANSLATION_START,
  EDIT_TRANSLATION_CANCEL,
  EDIT_TRANSLATION_SUBMIT,
  DELETE_TRANSLATION,
  CLEAR_TRANSLATIONS_DATA
} from '../actions';

export const loadLanguage = (languageCode) => ({
  type: LOAD_LANGUAGE_LOADING,
  payload: languageCode
})

export const loadTranslations = () => ({
  type: LOAD_TRANSLATIONS_LOADING
})

export const editTranslationStart = (languageCode, slug) => ({
  type: EDIT_TRANSLATION_START,
  payload: {
    languageCode: languageCode,
    slug: slug
  }
})

export const editTranslationCancel = (languageCode, slug) => ({
  type: EDIT_TRANSLATION_CANCEL,
  payload: {
    languageCode: languageCode,
    slug: slug
  }
})

export const editTranslationSubmit = (languageCode, slug, value, isEdit) => ({
  type: EDIT_TRANSLATION_SUBMIT,
  payload: {
    languageCode: languageCode,
    slug: slug,
    value: value,
    isEdit: isEdit
  }
})

export const deleteTranslation = (id) => ({
  type: DELETE_TRANSLATION,
  payload: {
    id: id
  }
})

export const clearTranslationsData = () => ({
  type: CLEAR_TRANSLATIONS_DATA
})