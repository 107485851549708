import { takeLatest, put } from 'redux-saga/effects';

import {
    UPLOAD_FILE,
    UPLOAD_FILE_SUCCESS,
    GET_STRUCTURE_SUCCESS
} from '../actions';
import {NotificationManager} from "../../components/common/react-notifications";
import IntlMessages from "../../helpers/IntlMessages";
import React from "react";
import {postFile, getStructure } from "../../helpers/Api";


async function fetchAsync(func, data) {
    const response = await func(data);
    if (response.status === 200 && response) {
        return await response.data;
    }
    throw new Error(response.message);
}

const uploadFileAsync = async (data) =>
    await postFile(data)
        .then(file_data => {
            return file_data;
        })
        .catch(error => error);

const getStructureAsync = async (data) =>
    await getStructure(data)
        .then(structure_data => {
            return structure_data;
        })
        .catch(error => error);


function* uploadFile( {payload} ) {

    try {

        const file_data = yield fetchAsync(uploadFileAsync, payload);
        yield put({type: UPLOAD_FILE_SUCCESS, data: file_data});

        /* get file sample if the extension is xml or json*/
        const data = {
            "transportType": "file-upload",
            "authFields": {
            },
            "configuration": {
            "path": file_data.path,
            "ioType": file_data.ioType
        }
        }
       // yield getStructureData(data);
    }
    catch (e) {
        //yield put({type: LOAD_COMPANY_DETAILS_ERROR, error: e.message});

        /*NotificationManager.error(
            <IntlMessages id={e.message} />,
            <IntlMessages id="error" />,
            3000,
            null,
            null,
            ''
        );*/
    }
}

function* getStructureData( data ) {

    try {

        const structure_data = yield fetchAsync(getStructureAsync, data);

        yield put({type: GET_STRUCTURE_SUCCESS, data: structure_data.data});

    }
    catch (e) {
        //yield put({type: LOAD_COMPANY_DETAILS_ERROR, error: e.message});

        /*NotificationManager.error(
            <IntlMessages id={e.message} />,
            <IntlMessages id="error" />,
            3000,
            null,
            null,
            ''
        );*/
    }
}

export function* rootSaga(  ) {
    yield takeLatest(UPLOAD_FILE, uploadFile);
}

export default rootSaga;
