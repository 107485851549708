import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {companyDetails, login, register, watchTimeout, userConfirm, changePassword, forgotPasswordPost} from "../../helpers/Api";
import {
    LOAD_COMPANY_DETAILS_ERROR,
    LOAD_COMPANY_DETAILS_SUCCESS,
    LOGIN_USER,
    LOGOUT_USER,
    REGISTER_USER,
    CHECK_TIMEOUT,
    FORGOT_PASSWORD,
    USER_CONFIRM,
    USER_CHANGE_PASSWORD
} from '../actions';

import {
    loginUserSuccess,
    loginUserError,
    registerUserSuccess,
    registerUserError,
    userConfirmError,
    userConfirmSuccess,
    forgotPasswordError,
    forgotPasswordSuccess,
    userChangePasswordError,
    userChangePasswordSuccess
} from './actions';
import {NotificationManager} from "../../components/common/react-notifications";
import IntlMessages from "../../helpers/IntlMessages";
import React from "react";


export function* watchRegisterUser() {
    yield takeEvery(REGISTER_USER, registerUser);
}

export function* watchConfirmUser() {
    yield takeEvery(USER_CONFIRM, confirmUser)
}

export function* watchChangePassword() {
    yield  takeEvery(USER_CHANGE_PASSWORD, userChangePassword)
}

export function* watchCheckUserTimeout() {
    yield takeEvery(CHECK_TIMEOUT, checkTimeoutUser);
}

export function* watchLoginUser() {
    yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

export function* watchForgotPassword() {
    yield takeEvery(FORGOT_PASSWORD, forgotPassword)
}

const loginWithEmailPasswordAsync = async ( email, password, firstName, lastName ) =>
    await login(email, password)
        .then(authUser => {
            return authUser.data;
        })
        .catch(error => error);

const registerUserAsync = async ( email, password, firstName, lastName, url_confirm, confirmPassword ) =>
    await register(email, password, firstName, lastName, url_confirm, confirmPassword)
        .then(registeredUser => {
            return registeredUser;
        })
        .catch(error => error);

const forgotPasswordAsync = async ( email, url_confirm) =>
    await  forgotPasswordPost(email, url_confirm)
        .then( res => {
            return res;
        } )
        .catch( err => err );


const confirmUserAsync = async  (token) =>
      await userConfirm(token)
        .then(response => {
            return response;
        })
        .catch(error => error );


const changePasswordAsync = async  ({password, token}) =>
      await changePassword(password, token)
        .then(response => {
            return response;
        })
        .catch(error => error );


const checkTimeoutUserAsync = async ( ) =>
    await watchTimeout()
        .then(response => {
            return response.data;
        })
        .catch(error => error);

function* registerUser( { payload } ) {

    const { email, password, firstName, lastName, url_confirm, confirmPassword } = payload.user;
    const { history } = payload;
    try {
        const registerUser = yield call(registerUserAsync, email, password, firstName, lastName, url_confirm, confirmPassword);
        const response = registerUser.data;

        if ( registerUser.status && registerUser.status === 200 && response ) {
            //localStorage.setItem('user_id', response.identity.id);
            //localStorage.setItem('user_token', response.token);
            yield put(registerUserSuccess('Registered!'));
            history.push('/user/login');
        } else {
            yield put(registerUserError(registerUser.message));
        }
    } catch (error) {
        yield put(registerUserError(error));

    }
}

function* forgotPassword( { payload } ) {
    const { email, url_confirm} = payload.forgotUserMail;
    const { history } = payload;

    try {
        const forgotPasswordRequest = yield call(forgotPasswordAsync, email, url_confirm);
        const response = forgotPasswordRequest.data;
        if (  response && response.status && response.status === 200) {
            yield put(forgotPasswordSuccess('EmailSent!'));
            history.push('/user/login');
        } else {
            yield put(forgotPasswordError(response.message));
        }
    } catch (e) {
        yield put(forgotPasswordError(e));
    }
}

function* confirmUser ( request ) {
    const token = request.payload.confirmToken;
    try {
        const userConfirm = yield call(confirmUserAsync, token);
        const response = userConfirm.data;

        if(userConfirm.status && userConfirm.status === 200 && response){
            yield put(userConfirmSuccess("Success"));
        }else{
            yield put(userConfirmError(userConfirm.message));
        }
    }catch (error) {
        yield put(userConfirmError())
    }
}

function* userChangePassword ( {payload} ) {
    const {password, token } = payload.changePasswordData;
    const {history} = payload;
    try {
        const  changePasswordRequest = yield call(changePasswordAsync, {password, token})
        const response = changePasswordRequest.data;
        if( response && response.status && response.status === 200) {
            yield userChangePasswordSuccess(response.message);
            history.push('/user/login');
        }else{
            yield userChangePasswordError(response.message);
        }
    }catch (error) {
        yield userChangePasswordError(error);
    }
}

function* checkTimeoutUser( {payload} ) {

    const { history } = payload;

    try {
        const response = yield call(checkTimeoutUserAsync);

        if ( response.status !== 200 ) {

            yield logout({payload: payload});

        }
    } catch (error) {
    }
}


function* loginWithEmailPassword( { payload } ) {
    const { email, password } = payload.user;
    const { history } = payload;
    try {
        const loginUser = yield call(loginWithEmailPasswordAsync, email, password);
        const response = loginUser.data;

        if ( loginUser.status && loginUser.status === 200 && response ) {
            localStorage.setItem('user_id', response.identity.id);
            localStorage.setItem('user_token', response.token);
            localStorage.setItem('firstname', response.identity.first_name);
            yield put(loginUserSuccess(response.identity));
            history.push('/');
        } else {
            yield put(loginUserError(loginUser.message));
        }
    } catch (error) {
        yield put(loginUserError(error));

    }
}


export function* watchLogoutUser() {
    yield takeEvery(LOGOUT_USER, logout);
}

const logoutAsync = async ( history ) => {
    history.push('/user/login')
};

function* logout( { payload } ) {
    console.log('payload logout')
    console.log(payload)
    const { history } = payload
    try {
        yield call(logoutAsync, history);
        localStorage.removeItem('user_id');
        localStorage.removeItem('user_token')
    } catch (error) {
    }
}

export default function* rootSaga() {
    yield all([
        fork(watchLoginUser),
        fork(watchLogoutUser),
        fork(watchRegisterUser),
        fork(watchCheckUserTimeout),
        fork(watchConfirmUser),
        fork(watchChangePassword),
        fork(watchForgotPassword)
    ]);
}
