import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { getLanguageStrings, getAllLanguages, getTranslations, postTranslation, delTranslation } from "../../helpers/Api";

import {
    LOAD_LANGUAGE_LOADING,
    LOAD_LANGUAGE_SUCCESS,
    LOAD_LANGUAGE_ERROR,
    LOAD_TRANSLATIONS_LOADING,
    LOAD_TRANSLATIONS_SUCCESS,
    LOAD_TRANSLATIONS_ERROR,
    EDIT_TRANSLATION_SUBMIT,
    EDIT_TRANSLATION_SUBMIT_SUCCESS,
    EDIT_TRANSLATION_SUBMIT_ERROR,
    DELETE_TRANSLATION,
    DELETE_TRANSLATION_ERROR
} from '../actions';

import {
    loadLanguage
} from './actions';
import { addLocaleData } from "react-intl";
import AppLocale from "../../lang";
import { NotificationManager } from "../../components/common/react-notifications";
import IntlMessages from "../../helpers/IntlMessages";
import React from "react";

async function fetchAsync(func, languageCode) {
    const response = await func(languageCode);
    if (response.status === 200 && response) {
        return await response.data;
    }

    throw new Error("Unexpected error!!!");
}

function* fetchLanguage({ payload }) {

    try {
        const languageCode = payload + '-' + payload.toUpperCase();

        const language = yield fetchAsync(getLanguageStrings, languageCode);

        const languageObj = {
            data: [],
            locale: payload,
            messages: {}
        };

        Object.keys(language.data).map(function (key) {
            languageObj.messages[language.data[key].slug] = language.data[key].value;
        });

        yield put({ type: LOAD_LANGUAGE_SUCCESS, data: languageObj });
    }
    catch (e) {
        yield put({ type: LOAD_LANGUAGE_ERROR, error: e.message });
    }
}

function* fetchTranslations() {

    try {
        const languages = yield fetchAsync(getAllLanguages, null);
        let translations = {};
        for (let key in languages.data) {
            const language_code = languages.data[key].slug;
            translations[language_code] = languages.data[key];
            const translationData = yield fetchAsync(getTranslations, language_code);
            translations[language_code].translations = {};
            for (let k in translationData.data) {
                const slug = translationData.data[k].slug;
                translations[language_code].translations[slug] = translationData.data[k];
                translations[language_code].translations[slug].editing = false;
            }
        }
        yield put({ type: LOAD_TRANSLATIONS_SUCCESS, translations: translations });
    }
    catch (e) {
        yield put({ type: LOAD_TRANSLATIONS_ERROR, error: e.message });

        NotificationManager.error(
            <IntlMessages id={e.message} />,
            <IntlMessages id="error" />,
            3000,
            null,
            null,
            ''
        );
    }
}

function* updateTranslation({ payload }) {
    try {
        const language_slug = payload.languageCode;
        const slug = payload.slug;
        const value = payload.value;
        const isEdit = payload.isEdit;

        const postObj = {
            isEdit: isEdit,
            details: {
                language_slug: language_slug,
                slug: slug,
                value: value
            }
        }
        const response = yield fetchAsync(postTranslation, postObj)

        yield put({ type: EDIT_TRANSLATION_SUBMIT_SUCCESS });
        yield put({ type: LOAD_TRANSLATIONS_LOADING });

        NotificationManager.success(
            <IntlMessages id="post_translation_success" />,
            <IntlMessages id="success" />,
            3000,
            null,
            null,
            ''
        );
    }
    catch (e) {
        yield put({ type: EDIT_TRANSLATION_SUBMIT_ERROR, error: e.message });

        NotificationManager.error(
            <IntlMessages id={e.message} />,
            <IntlMessages id="error" />,
            3000,
            null,
            null,
            ''
        );
    }
}

function* deleteTranslation({ payload }) {
    try {
        const id = payload.id;

        const response = yield fetchAsync(delTranslation, id)

        yield put({ type: LOAD_TRANSLATIONS_LOADING });

        NotificationManager.success(
            <IntlMessages id="deleted_successfully" />,
            <IntlMessages id="success" />,
            3000,
            null,
            null,
            ''
        );
    }
    catch (e) {
        yield put({ type: DELETE_TRANSLATION_ERROR, error: e.message });

        NotificationManager.error(
            <IntlMessages id={e.message} />,
            <IntlMessages id="error" />,
            3000,
            null,
            null,
            ''
        );
    }
}

export function* languageSaga() {
    yield takeLatest(LOAD_LANGUAGE_LOADING, fetchLanguage);
    yield takeLatest(LOAD_TRANSLATIONS_LOADING, fetchTranslations);
    yield takeEvery(EDIT_TRANSLATION_SUBMIT, updateTranslation);
    yield takeEvery(DELETE_TRANSLATION, deleteTranslation);
}

export default languageSaga;
