import {
    LOAD_TRANSPORT_LOADING,
    LOAD_TRANSPORT_SUCCESS,
    LOAD_TRANSPORT_ERROR,
    LOAD_IOTYPE_LOADING,
    LOAD_IOTYPE_SUCCESS,
    LOAD_IOTYPE_ERROR,
    POST_TRANSPORT_LOADING,
    POST_TRANSPORT_SUCCESS,
    POST_TRANSPORT_ERROR,
    TEST_TRANSPORT_LOADING,
    TEST_TRANSPORT_SUCCESS,
    TEST_TRANSPORT_ERROR,
    LOAD_TRANSPORT_IDENTITY_LOADING,
    LOAD_TRANSPORT_IDENTITY_SUCCESS,
    LOAD_TRANSPORT_IDENTITY_FROM_SUCCESS,
    LOAD_TRANSPORT_IDENTITY_TO_SUCCESS,
    LOAD_TRANSPORT_IDENTITY_BY_ID_LOADING,
    LOAD_TRANSPORT_IDENTITY_HEADERS_LOADING,
    CLEAR_TRANSPORT_IDENTITY,
    LOAD_TRANSPORT_IDENTITY_BY_ID_SUCCESS,
    LOAD_FLOWS_LOADING,
    LOAD_FLOWS_SUCCESS,
    DELETE_DESTINATION_LIST_ITEM,
    MOVE_LIST_ITEM_UP,
    MOVE_LIST_ITEM_DOWN,
    LOAD_FLOW_BY_ID_SUCCESS,
    SET_FLOW_HEADERS,
    LOAD_FLOW_LOADING,
    LOAD_STRUCTURE_SUCCESS,
    LOAD_STRUCTURE_ERROR, LOAD_STRUCTURE_LOADING,
    NEW_TRANSPORT_IDENTITY_DESTINATION_FIELD,
} from '../actions';

const initialState = {   data: {},   loading: true,   error: '', ioTypeLoading: true, postTransportMessage: '', testTransportMessage: '',
    headers: { destination_from: [], destination_to: [] }, 
    structure: {structure: {}, xpath:''}, structureLoading: false };

export default function reduxSagaReducer(state = initialState, action) {

    switch (action.type) {

        case LOAD_TRANSPORT_LOADING: {

            return {
                ...state,
                loading: true,
                error:''
            };

        }
        case LOAD_STRUCTURE_LOADING: {

            return {
                ...state,
                structureLoading: true,
                error:''
            };

        }

        case LOAD_FLOW_LOADING: {

            return {
                ...state,
                loading: true,
                error:''
            };

        }

        case LOAD_FLOWS_LOADING: {

            return {
                ...state,
                loading: true,
                error:''
            };

        }

        case LOAD_FLOWS_SUCCESS: {

            return {
                ...state,
                flowData: action.data
            };

        }

        case LOAD_TRANSPORT_SUCCESS: {

            return {
                ...state,
                data: action.data,
                loading: false
            }

        }

        case LOAD_TRANSPORT_ERROR: {

            return {
                ...state,
                loading: false,
                error: action.error
            };

        }

        case LOAD_IOTYPE_LOADING: {

            return {
                ...state,
                ioTypeLoading: true,
                error:''

            };

        }

        case LOAD_IOTYPE_SUCCESS: {

            return {
                ...state,
                ioTypeData: action.data,
                ioTypeLoading: false
            }

        }

        case LOAD_IOTYPE_ERROR: {

            return {
                ...state,
                ioTypeLoading: false,
                error: action.error
            };

        }

        case POST_TRANSPORT_LOADING: {

            return {
                ...state,
                postTransportLoading: true,
                error:''
            };

        }

        case POST_TRANSPORT_SUCCESS: {

            return {
                ...state,
                postTransportMessage: action.data,
                postTransportLoading: false
            }

        }

        case POST_TRANSPORT_ERROR: {

            return {
                ...state,
                postTransportLoading: false,
                error: action.error
            };

        }

        case TEST_TRANSPORT_LOADING: {

            return {
                ...state,
                testTransportLoading: true,
                error:''

            };

        }

        case LOAD_TRANSPORT_IDENTITY_LOADING: {

            return {
                ...state,
                identityTransportLoading: true,
                error:''

            };

        }

        case LOAD_TRANSPORT_IDENTITY_HEADERS_LOADING: {

            let headerListName = 'destination_'+ action.payload.source;

            state.headers[headerListName] = [];

            return {
                ...state,
                identityTransportByIdLoading: true,
                loading: true,
                error:''
            };

        }

        case LOAD_TRANSPORT_IDENTITY_BY_ID_LOADING: {

            return {
                ...state,
                identityTransportLoading: true,
                error:''
            };

        }

        case LOAD_TRANSPORT_IDENTITY_BY_ID_SUCCESS: {

            return {
                ...state,
                identityTransportData: action.data,
                error:''
            };

        }

        case CLEAR_TRANSPORT_IDENTITY: {

            return  initialState

        }

        case LOAD_TRANSPORT_IDENTITY_FROM_SUCCESS: {

            return {
                ...state,
                headers: {...state.headers, [action.data.group] : action.data.headers },
                loading: false
            }

        }

        case NEW_TRANSPORT_IDENTITY_DESTINATION_FIELD: {
            const newField = { id: action.fieldName + new Date().getTime(), name: action.fieldName}
            return  {
                ...state,
                headers: {
                    ...state.headers,
                    source_to: [...state.headers.source_to, newField]
                }
            }
        }

        case 'UPDATED_HEADER': {

            const elementToMove = state.headers[action.payload.source.droppableId][action.payload.draggedId];

            state.headers[action.payload.source.droppableId].splice(action.payload.source.index, 1);
            state.headers[action.payload.destination.droppableId].splice(0 , 0, elementToMove);

            return {
                ...state,
                headers: state.headers
            }

        }

        case 'SET_FLOW_HEADERS': {

            return {
                ...state,
                headers: action.data
            }

        }

        case LOAD_TRANSPORT_IDENTITY_TO_SUCCESS: {

            return {
                ...state,
                transportIdentityToArray: action.data,
                loading: false
            }

        }

        case LOAD_TRANSPORT_IDENTITY_SUCCESS: {

            return {
                ...state,
                transportIdentityArray: action.data,
                loading: false
            }

        }

        case TEST_TRANSPORT_SUCCESS: {

            return {
                ...state,
                testTransportMessage: action.data,
                postTransportLoading: false
            }

        }

        case LOAD_STRUCTURE_SUCCESS: {

            return {
                ...state,
                structure: action.data,
                structureLoading: false
            }

        }

        case LOAD_STRUCTURE_ERROR: {

            return {
                ...state,
                structureLoading: false
               // error: action.error
            }

        }

        case TEST_TRANSPORT_ERROR: {

            return {
                ...state,
                testTransportLoading: false,
                error: action.error
            };

        }

        case LOAD_FLOW_BY_ID_SUCCESS: {

            return {
                ...state,
                initialFlowData: action.data
            };

        }

        case DELETE_DESTINATION_LIST_ITEM: {
            const source = action.payload.source

            const elementToMove =  state.headers["destination_"+source][action.payload.index];

            delete state.headers["destination_"+source][action.payload.index];
            state.headers["source_"+source].splice(0 , 0, elementToMove);

            const reorderedList = []
            state.headers["destination_"+source].map( item => {
                if (typeof item !== 'undefined'){
                    reorderedList.push(item)
                }
            })


            return {
                ...state,
                headers: {
                    ...state.headers,
                    ["destination_"+source]:reorderedList
                }
            }

        }

        case MOVE_LIST_ITEM_UP: {

            const source = action.payload.source

            if (action.payload.index == 0){
                return {
                    ...state
                }
            }

            const elementToMove =  state.headers["destination_"+source][action.payload.index];

            state.headers["destination_"+source][action.payload.index] = state.headers["destination_"+source][action.payload.index - 1];
            state.headers["destination_"+source][action.payload.index - 1] = elementToMove

            return {
                ...state,
                headers: {
                    ...state.headers,
                }
            }

        }

        case MOVE_LIST_ITEM_DOWN: {

            const source = action.payload.source

            if ( action.payload.index + 1 == state.headers["destination_"+source].length){
                return {
                    ...state
                }
            }

            const elementToMove =  state.headers["destination_"+source][action.payload.index];

            state.headers["destination_"+source][action.payload.index] = state.headers["destination_"+source][action.payload.index + 1];
            state.headers["destination_"+source][action.payload.index + 1] = elementToMove

            return {
                ...state,
                headers: {
                    ...state.headers,
                }
            }

        }

        default: {

            return state;

        }

    }

}
