/*
import { addLocaleData } from 'react-intl';
import enLang from './entries/en-US';
import esLang from './entries/es-ES';
import enRtlLang from './entries/en-US-rtl';

const AppLocale = {
    en: enLang,
    es: esLang,
    enrtl:enRtlLang
};

/!*
console.log('midig bejon');

addLocaleData(AppLocale.en.data);
addLocaleData(AppLocale.es.data);
addLocaleData(AppLocale.enrtl.data);
*!/

export default AppLocale;
*/
import { addLocaleData } from 'react-intl';
import appLocaleDataEN from 'react-intl/locale-data/en';
import appLocaleDataRO from 'react-intl/locale-data/ro';
addLocaleData(appLocaleDataEN);
addLocaleData(appLocaleDataRO);
