import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { deleteTransport, getTransport, postTransport } from "../../helpers/Api";
import { testTransportValid } from "../../helpers/FlowApi";

import { 
    DELETE_TRANSPORT, 
    LOAD_TRANSPORT_LOADING ,
    LOAD_TRANSPORT_TYPE_LOADING,
    LOAD_TRANSPORT_TYPE_SUCCESS,
    LOAD_TRANSPORT_TYPE_ERROR,
    POST_TRANSPORT_TYPE_LOADING
} from '../actions';

import { NotificationManager } from "../../components/common/react-notifications";
import IntlMessages from "../../helpers/IntlMessages";
import React from "react";

async function fetchAsync(func, param) {
    const response = await func(param);
    if (response.status === 200 && response) {
        return await response.data;
    }

    throw new Error("Unexpected error!!!");
}

function* deleteTransportById({ id }) {

    try {
        yield fetchAsync(deleteTransport, id);

        yield put({type: LOAD_TRANSPORT_LOADING});

        NotificationManager.success(
            <IntlMessages id="deleted_successfully" />,
            <IntlMessages id="success" />,
            3000,
            null,
            null,
            ''
        );
    }
    catch (e) {
        NotificationManager.error(
            <IntlMessages id={e.message} />,
            <IntlMessages id="error" />,
            3000,
            null,
            null,
            ''
        );
    }
}

function* fetchTransportBySlug({ slug }) {

    try {
        const transport = yield fetchAsync(getTransport, slug);

        yield put({type: LOAD_TRANSPORT_TYPE_SUCCESS, data: transport.data});
    }
    catch (e) {
        yield put({type: LOAD_TRANSPORT_TYPE_ERROR, error: e});

        NotificationManager.error(
            <IntlMessages id={e.message} />,
            <IntlMessages id="error" />,
            3000,
            null,
            null,
            ''
        );
    }
}

function* postTransportType({ payload }) {

    try {
        //console.log(payload.postObj)
        const postReturn = yield fetchAsync(postTransport, payload.postObj);
        console.log(postReturn)

        if (postReturn.status == 200) {
            NotificationManager.success(
                <IntlMessages id={postReturn.message}/>,
                <IntlMessages id="success"/>,
                3000,
                null,
                null,
                ''
            );
        }else{
            NotificationManager.error(
                <IntlMessages id={postReturn.message}/>,
                <IntlMessages id="error"/>,
                3000,
                null,
                null,
                ''
            );
        }

        payload.history.push('/app/admin/list-transport-type');
    }
    catch (e) {
        NotificationManager.error(
            <IntlMessages id={e.message} />,
            <IntlMessages id="error" />,
            3000,
            null,
            null,
            ''
        );
    }
}

function* testGoogleConnection({payload}) {
    try {
        const { transport_identity_id, history } = payload;
        const body = {
            "transportType": "google-drive",
            "id":"dd21e796-3637-11eb-974e-e86a640ab53c",
            "authFields": {
                "passive": {
                    "slug": "passive",
                    "type": "select",
                    "default": true,
                    "required": true,
                    "values": {
                        "true": "passive",
                        "false": "not passive"
                    }
                }
            }
        }
        const response = yield fetchAsync(testTransportValid, body);
        if(response.status === 400){
            //history.push(response.data.error); 
            // google auth link
            window.open(response.data.error)
        }
        console.log(response)
    }
    catch (e) {

        NotificationManager.error(
            <IntlMessages id={e.message} />,
            <IntlMessages id="error" />,
            3000,
            null,
            null,
            ''
        );
    }
}

export function* adminSaga() {
   yield takeLatest(DELETE_TRANSPORT, deleteTransportById);
   yield takeLatest(LOAD_TRANSPORT_TYPE_LOADING, fetchTransportBySlug);
   yield takeLatest(POST_TRANSPORT_TYPE_LOADING, postTransportType);
   yield takeLatest('TEST_GOOGLE_CONNECTION', testGoogleConnection);
}

export default adminSaga;
