import { combineReducers } from 'redux';
import settings from './settings/reducer';
import menu from './menu/reducer';
import authUser from './auth/reducer';
import languageStrings from './language/reducer';
import flow from './flow/reducer';
import gogo from './gogo/reducer';
import upload from './upload/reducer';
import admin from './admin/reducer';
import { reducer as reduxFormReducer } from 'redux-form';


const reducers = combineReducers({
  menu,
  settings,
  authUser,
  languageStrings,
  flow,
  gogo,
  upload,
  admin,
  form: reduxFormReducer
});

export default reducers;
