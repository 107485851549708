import {
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGOUT_USER,
    REGISTER_USER,
    REGISTER_USER_SUCCESS,
    LOGIN_USER_ERROR,
    REGISTER_USER_ERROR,
    FORGOT_PASSWORD,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_ERROR,
    RESET_PASSWORD,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_ERROR,
    CHECK_TIMEOUT,
    USER_CONFIRM,
    USER_CONFIRM_SUCCESS,
    USER_CONFIRM_ERROR, USER_CHANGE_PASSWORD_SUCCESS, USER_CHANGE_PASSWORD, USER_CHANGE_PASSWORD_ERROR
} from '../actions';

export const checkUserTimeout = (history) => ({
  type: CHECK_TIMEOUT,
  payload: {history}
});

export const loginUser = (user, history) => ({
  type: LOGIN_USER,
  payload: { user, history }
});
export const loginUserSuccess = (user) => ({
  type: LOGIN_USER_SUCCESS,
  payload: user
});
export const loginUserError = (message) => ({
  type: LOGIN_USER_ERROR,
  payload: { message }
});

export const forgotPassword = (forgotPasswordData, history) => ({
  type: FORGOT_PASSWORD,
  payload: { forgotPasswordData, history }
});
export const forgotPasswordSuccess = (forgotUserMail) => ({
  type: FORGOT_PASSWORD_SUCCESS,
  payload: forgotUserMail
});
export const forgotPasswordError = (message) => ({
  type: FORGOT_PASSWORD_ERROR,
  payload: { message }
});

export const resetPassword = ({resetPasswordCode,newPassword,history}) => ({
  type: RESET_PASSWORD,
  payload: { resetPasswordCode,newPassword,history }
});
export const resetPasswordSuccess = (newPassword) => ({
  type: RESET_PASSWORD_SUCCESS,
  payload: newPassword
});
export const resetPasswordError = (message) => ({
  type: RESET_PASSWORD_ERROR,
  payload: { message }
});



export const registerUser = (user, history) => ({
  type: REGISTER_USER,
  payload: { user, history }
})
export const registerUserSuccess = (user) => ({
  type: REGISTER_USER_SUCCESS,
  payload: user
})
export const registerUserError = (message) => ({
  type: REGISTER_USER_ERROR,
  payload: { message }
})

export const userConfirm = (confirmToken) => ({
    type: USER_CONFIRM,
    payload: {confirmToken}
})

export const userConfirmSuccess = (message) => ({
    type: USER_CONFIRM_SUCCESS,
    payload: {message}
})

export const userConfirmError = (message) => ({
    type: USER_CONFIRM_ERROR,
    payload: {message}
})

export const userChangePassword = (changePasswordData, history) => ({
    type: USER_CHANGE_PASSWORD,
    payload: {changePasswordData, history}
})

export const userChangePasswordSuccess = (message) => ({
    type: USER_CHANGE_PASSWORD_SUCCESS,
    payload: {message}
})

export const userChangePasswordError = (message) => ({
    type: USER_CHANGE_PASSWORD_ERROR,
    payload: {message}
})

export const logoutUser = (history) => ({
  type: LOGOUT_USER,
  payload: { history }
});
