import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { fetchTransports, fetchIoTypes, createTransport, testTransportValid, fetchIdentityTransports,
    fetchIdentityTransportHeaders, fetchIdentityTransportById, deleteIdentityTransports, postFlow, deleteFlow,
    fetchFlows, fetchFlowById } from "../../helpers/FlowApi";

import {getStructure } from "../../helpers/Api";

import {
    LOAD_TRANSPORT_LOADING,
    LOAD_TRANSPORT_SUCCESS,
    LOAD_TRANSPORT_ERROR,
    LOAD_IOTYPE_LOADING,
    LOAD_IOTYPE_SUCCESS,
    LOAD_IOTYPE_ERROR,
    POST_TRANSPORT_LOADING,
    POST_TRANSPORT_SUCCESS,
    POST_TRANSPORT_ERROR,
    TEST_TRANSPORT_LOADING,
    TEST_TRANSPORT_ERROR,
    LOAD_TRANSPORT_IDENTITY_LOADING,
    LOAD_TRANSPORT_IDENTITY_SUCCESS,
    LOAD_TRANSPORT_IDENTITY_BY_ID_LOADING,
    LOAD_TRANSPORT_IDENTITY_FROM_SUCCESS,
    LOAD_TRANSPORT_IDENTITY_HEADERS_LOADING,
    LOAD_TRANSPORT_IDENTITY_BY_ID_SUCCESS,
    DELETE_TRANSPORT_IDENTITY, LOAD_FLOWS_LOADING, LOAD_FLOWS_SUCCESS,
    SAVE_FLOW,
    DELETE_FLOW,
    LOAD_FLOW_LOADING,
    LOAD_FLOW_BY_ID_SUCCESS,
    SET_FLOW_HEADERS,
    LOAD_STRUCTURE_LOADING, LOAD_STRUCTURE_SUCCESS,
    LOAD_STRUCTURE_ERROR

} from '../actions';
import {NotificationManager} from "../../components/common/react-notifications";
import React from "react";
import IntlMessages from "../../helpers/IntlMessages"


async function fetchAsync(func, data) {
    const response = await func(data);
    if (response.status === 200 && response) {
        return await response.data;
    }

    //throw new Error("Unexpected error!!!");
    throw new Error(response.message);
}

const loadTransportsAsync = async () =>
    await fetchTransports()
        .then(transports => {
            return transports.data;
        })
        .catch(error => error);

const loadFlowsAsync = async () =>
    await fetchFlows()
        .then(flows => {
            return flows.data;
        })
        .catch(error => error);

const loadFlowByIdAsync = async (id) =>
    await fetchFlowById(id)
        .then(flows => {
            return flows.data;
        })
        .catch(error => error);

const saveFlowAsync = async (data) =>
    await postFlow(data)
        .then(flows => {
            return flows;
        })
        .catch(error => error);

const loadIdentityTransportsAsync = async () =>
    await fetchIdentityTransports()
        .then(transports => {
            return transports.data;
        })
        .catch(error => error);

const loadTransportIdentityByIDAsync = async (id) =>
    await fetchIdentityTransportById(id)
        .then(transports => {
            return transports.data;
        })
        .catch(error => error);

const deleteTransportIdentityByIDAsync = async (id) =>
    await deleteIdentityTransports(id)
        .then(transports => {
            return transports.data;
        })
        .catch(error => error);

const deleteFlowByIDAsync = async (id) =>
    await deleteFlow(id)
        .then(transports => {
            return transports.data;
        })
        .catch(error => error);

const loadTransportIdentityHeadersAsync = async (id) =>
    await fetchIdentityTransportHeaders(id)
        .then(transports => {
            return transports.data;
        })
        .catch(error => error);

const postTransportsAsync = async (data) =>
    await createTransport(data)
        .then(transports => {
            return transports.data;
        })
        .catch(error => error);

const testTransportsAsync = async (data) =>
    await testTransportValid(data)
        .then(transports => {
            return transports.data;
        })
        .catch(error => error);


const loadIoTypes = async () =>
    await fetchIoTypes()
        .then(transports => {
            return transports.data;
        })
        .catch(error => error);

const getStructureAsync = async (data) =>
    await getStructure(data)
        .then(structure_data => {
            return structure_data;
        })
        .catch(error => error);

function* loadTransports( {payload} ) {

    try {

        const transports = yield fetchAsync(loadTransportsAsync);

        yield put({type: LOAD_TRANSPORT_SUCCESS, data: transports});
    }
    catch (e) {
        yield put({type: LOAD_TRANSPORT_ERROR, error: e.message});
    }
}

function* loadFlows( {payload} ) {

    try {

        const flowData = yield fetchAsync(loadFlowsAsync);

        yield put({type: LOAD_FLOWS_SUCCESS, data: flowData});
    }
    catch (e) {
       // yield put({type: LOAD_TRANSPORT_ERROR, error: e.message});
    }
}
function* loadFlowById( {payload} ) {

    try {

        //get flow data
        const flowData = yield fetchAsync(loadFlowByIdAsync, payload);

        const import_transport_identity_id = flowData.import_transport_identity_id
        const export_transport_identity_id = flowData.export_transport_identity_id

        //get transport identity source headers
        const sourceHeadersObj = yield fetchAsync(loadTransportIdentityHeadersAsync, import_transport_identity_id);

        //get transport identity destination headers
        const destinationHeadersObj = yield fetchAsync(loadTransportIdentityHeadersAsync, export_transport_identity_id);

        let headers_source_from = []
        let headers_source_to = []
        let headers_destination_from = []
        let headers_destination_to = []


        flowData.mapping_details.mapping.map((item, index) => {
            delete sourceHeadersObj[item.importId]
            headers_destination_from.push(  {id: item.importId, name: item.importName } )

            delete destinationHeadersObj[item.exportId]
            headers_destination_to.push(  {id: item.exportId, name: item.exportName } )
        })

        Object.keys(sourceHeadersObj).map((index) =>
            headers_source_from.push(  {id: index, name: sourceHeadersObj[index] } )
        )

        Object.keys(destinationHeadersObj).map((index) =>
            headers_source_to.push(  {id: index, name: destinationHeadersObj[index] } )
        )


        yield put({type: LOAD_FLOW_BY_ID_SUCCESS, data: flowData});
        yield put({type: SET_FLOW_HEADERS, data: {
                destination_from: headers_destination_from,
                destination_to: headers_destination_to,
                source_from: headers_source_from,
                source_to: headers_source_to
            }});
    }
    catch (e) {
       // yield put({type: LOAD_TRANSPORT_ERROR, error: e.message});
    }
}

function* saveFlow( {payload} ) {

    try {

        const postReturn = yield fetchAsync(saveFlowAsync, payload);

        if (postReturn.status == 200) {
            NotificationManager.success(
                <IntlMessages id={postReturn.message}/>,
                <IntlMessages id="success"/>,
                3000,
                null,
                null,
                ''
            );
        }else{
            NotificationManager.error(
                <IntlMessages id={postReturn.message}/>,
                <IntlMessages id="error"/>,
                3000,
                null,
                null,
                ''
            );
        }

        //yield put({type: LOAD_FLOWS_SUCCESS, data: flowData});
    }
    catch (e) {
       // yield put({type: LOAD_TRANSPORT_ERROR, error: e.message});
    }
}

function* loadIdentityTransports( {payload} ) {

    try {

        const transports = yield fetchAsync(loadIdentityTransportsAsync);

        yield put({type: LOAD_TRANSPORT_IDENTITY_SUCCESS, data: transports});
    }
    catch (e) {
       // yield put({type: LOAD_TRANSPORT_ERROR, error: e.message});
    }
}

function* loadTransportIdentityByID( {id} ) {

    try {

        const transports =  yield fetchAsync(loadTransportIdentityByIDAsync, id);

        yield put({type: LOAD_TRANSPORT_IDENTITY_BY_ID_SUCCESS, data: transports});
    }
    catch (e) {
        NotificationManager.error(
            <IntlMessages id={e.message} />,
            <IntlMessages id="error" />,
            3000,
            null,
            null,
            ''
        );
        // yield put({type: LOAD_TRANSPORT_ERROR, error: e.message});
    }
}

function* deleteFlowByID( {id} ) {

    try {

        yield fetchAsync(deleteFlowByIDAsync, id);

        yield loadFlows({});

        NotificationManager.success(
            <IntlMessages id="deleted_successfully" />,
            <IntlMessages id="success" />,
            3000,
            null,
            null,
            ''
        );
    }
    catch (e) {
       /* NotificationManager.error(
            <IntlMessages id={e.message} />,
            <IntlMessages id="error" />,
            3000,
            null,
            null,
            ''
        );*/
        // yield put({type: LOAD_TRANSPORT_ERROR, error: e.message});
    }
}

function* deleteTransportIdentityByID( {id} ) {

    try {

        yield fetchAsync(deleteTransportIdentityByIDAsync, id);

        yield loadIdentityTransports({});

        NotificationManager.success(
            <IntlMessages id="deleted_successfully" />,
            <IntlMessages id="success" />,
            3000,
            null,
            null,
            ''
        );
    }
    catch (e) {
        NotificationManager.error(
            <IntlMessages id={e.message} />,
            <IntlMessages id="error" />,
            3000,
            null,
            null,
            ''
        );
        // yield put({type: LOAD_TRANSPORT_ERROR, error: e.message});
    }
}

function* loadTransportIdentityHeaders( {payload} ) {

    try {

        const headersObj = yield fetchAsync(loadTransportIdentityHeadersAsync, payload.id);
        let headers = []
        Object.keys(headersObj).map((index) =>
            headers.push(  {id: index, name: headersObj[index] } )
        )


        if (payload.source == 'from'){
            yield put({type: LOAD_TRANSPORT_IDENTITY_FROM_SUCCESS,  data: {headers: headers, group: "source_from"} });
        }else{
            yield put({type: LOAD_TRANSPORT_IDENTITY_FROM_SUCCESS, data: {headers: headers, group: "source_to"}});
        }

    }
    catch (e) {

        if (payload.source == 'from'){
            yield put({type: LOAD_TRANSPORT_IDENTITY_FROM_SUCCESS,  data: {headers: [], group: "source_from"} });
        }else{
            yield put({type: LOAD_TRANSPORT_IDENTITY_FROM_SUCCESS, data: {headers: [], group: "source_to"}});
        }

        NotificationManager.error(
            <IntlMessages id={e.message} />,
            <IntlMessages id="error" />,
            3000,
            null,
            null,
            ''
        );
        //todo
       // yield put({type: LOAD_TRANSPORT_ERROR, error: e.message});
    }
}

function* postTransport( {payload} ) {

    try {

        const transport = yield fetchAsync(postTransportsAsync, payload.data);

        NotificationManager.success(
            <IntlMessages id="post_transport_success" />,
            <IntlMessages id="success" />,
            3000,
            null,
            null,
            ''
        );

        yield put({type: POST_TRANSPORT_SUCCESS, data: "post_transport_success"});
        payload.history.push('/app/flow/transport-identities');
    }
    catch (e) {

        NotificationManager.error(
            <IntlMessages id={e.message} />,
            <IntlMessages id="error" />,
            3000,
            null,
            null,
            ''
        );

        yield put({type: POST_TRANSPORT_ERROR, error: e.message});
    }
}

function* testTransport( {payload} ) {

    try {

        const transport = yield fetchAsync(testTransportsAsync, payload);

        NotificationManager.success(
            <IntlMessages id="test_transport_success" />,
            <IntlMessages id="success" />,
            3000,
            null,
            null,
            ''
        );

       // yield put({type: TEST_TRANSPORT_SUCCESS, data: "test_transport_success"});
    }
    catch (e) {
        NotificationManager.error(
            <IntlMessages id={e.message} />,
            <IntlMessages id="error" />,
            3000,
            null,
            null,
            ''
        );
        yield put({type: TEST_TRANSPORT_ERROR, error: e.message});
    }
}


function* loadIoType( {payload} ) {

    try {

        const ioTypes = yield fetchAsync(loadIoTypes);

        yield put({type: LOAD_IOTYPE_SUCCESS, data: ioTypes});
    }
    catch (e) {
        yield put({type: LOAD_IOTYPE_ERROR, error: e.message});
    }
}

function* getStructureData( {payload} ) {

    try {

        const structure_data = yield fetchAsync(getStructureAsync, payload);

        if (structure_data.status !== 200){
            yield put({type: LOAD_STRUCTURE_ERROR, error: ''});
        }else {
            yield put({type: LOAD_STRUCTURE_SUCCESS, data: structure_data.data});
        }

    }
    catch (e) {
        yield put({type: LOAD_STRUCTURE_ERROR, error: ''});
        //yield put({type: LOAD_COMPANY_DETAILS_ERROR, error: e.message});

        /*NotificationManager.error(
            <IntlMessages id={e.message} />,
            <IntlMessages id="error" />,
            3000,
            null,
            null,
            ''
        );*/
    }
}

export function* rootSaga(  ) {
    yield takeLatest(LOAD_TRANSPORT_LOADING, loadTransports);
    yield takeLatest(POST_TRANSPORT_LOADING, postTransport);
    yield takeLatest(TEST_TRANSPORT_LOADING, testTransport);
    yield takeLatest(LOAD_IOTYPE_LOADING, loadIoType);
    yield takeLatest(LOAD_TRANSPORT_IDENTITY_LOADING, loadIdentityTransports);
    yield takeLatest(LOAD_TRANSPORT_IDENTITY_HEADERS_LOADING, loadTransportIdentityHeaders);
    yield takeLatest(LOAD_TRANSPORT_IDENTITY_BY_ID_LOADING, loadTransportIdentityByID);
    yield takeLatest(DELETE_TRANSPORT_IDENTITY, deleteTransportIdentityByID);
    yield takeLatest(DELETE_FLOW, deleteFlowByID);
    yield takeLatest(LOAD_FLOWS_LOADING, loadFlows);
    yield takeLatest(SAVE_FLOW, saveFlow);
    yield takeLatest(LOAD_FLOW_LOADING, loadFlowById);
    yield takeLatest(LOAD_STRUCTURE_LOADING, getStructureData);
}

export default rootSaga;
