import { apiConfig } from "../constants/defaultValues";
import axios from 'axios';

const login = (emailAddress, password) => {
    return axios.post(`${apiConfig.url}authorization/login`, {
        emailAddress,
        password
    })
};

const register = (emailAddress, password, first_name, last_name, url_confirm, confirmPassword) => {
    return axios.post(`${apiConfig.url}identity/create`, {
        emailAddress,
        password,
        first_name,
        last_name,
        confirmPassword,
        url_confirm
    })
};

const  userConfirm = (token) => {
    return axios.get(`${apiConfig.url}authorization/confirm/${token}`);
};

const changePassword = ( password, token ) => {
    return axios.post(`${apiConfig.url}authorization/change-password`, {password, token});
};

const forgotPasswordPost = ( emailAddress, url_confirm ) => {
    return axios.post(`${apiConfig.url}authorization/forgot-password`, {emailAddress, url_confirm});
};

const getLanguageStrings = (language_code) => {
    return axios.get(`${apiConfig.url}translation/${language_code}`, {
    })
};
const watchTimeout = () => {
    const options = generateHeaders()
    return axios.get(`${apiConfig.url}authorization/ping`, options)
};

const generateHeaders = () => {

    return  {
        headers: {'Authorization': 'Bearer ' + localStorage.getItem('user_token')}
    };
}

const companyDetails = (postData) => {
    const options = generateHeaders()
    return axios.get(`${apiConfig.url}organization`,  options)
};

const updateCompanyDetails = (postData) => {
    const options = generateHeaders()
    if (postData.isEdit){
        return axios.put(`${apiConfig.url}organization`, postData.details, options)
    }else {
        return axios.post(`${apiConfig.url}organization`, postData.details, options)
    }
};


const checkVatNr = (postData) => {
    const options = generateHeaders()
    return axios.post(`${apiConfig.url}organization/validate-vat`, postData, options)
};


const accountDetails = (postData) => {
    const options = generateHeaders()
    return axios.get(`${apiConfig.url}identity`,  options)
};

const updateAccountDetails = (postData) => {
    const options = generateHeaders()
    return axios.put(`${apiConfig.url}identity`, postData, options)
};

const postFile = (file) => {
    const options = generateHeaders()

    return axios.post(`${apiConfig.url}file/upload`, file, options)
};

const getStructure = (data) => {
    const options = generateHeaders()

    //return axios.post(`${apiConfig.url}gsb-sass/transport-identity/upload`, file, options)
    return axios.post(`${apiConfig.url}transport-identity/structure`, data, options)
};

const getAllLanguages = () => {
    return axios.get(`${apiConfig.url}language`, {
    })
};

const getTranslations = (language_code) => {
    return axios.get(`${apiConfig.url}translation/${language_code}`, {
    })
};

const postTranslation = (postData) => {
    const options = generateHeaders();
    if (postData.isEdit){
        return axios.put(`${apiConfig.url}translation`, postData.details, options)
    }else {
        return axios.post(`${apiConfig.url}translation`, postData.details, options)
    }
};

const delTranslation = (id) => {
    const options = generateHeaders();
    return axios.delete(`${apiConfig.url}translation/${id}`,  options)
};

const deleteTransport = (id) => {
    const options = generateHeaders()
    return axios.delete(`${apiConfig.url}transport/${id}`,  options)
};

const getTransport = (slug) => {
    const options = generateHeaders()
    return axios.get(`${apiConfig.url}transport/${slug}`, options)
};

const postTransport = (data) => {

    const options = generateHeaders()

    if (data.id){
        return axios.put(`${apiConfig.url}transport`, data, options)
    }else {
        return axios.post(`${apiConfig.url}transport`, data, options)
    }

};

export {
    login,
    register,
    getLanguageStrings,
    companyDetails,
    updateCompanyDetails,
    watchTimeout,
    checkVatNr,
    accountDetails,
    updateAccountDetails,
    userConfirm,
    changePassword,
    forgotPasswordPost,
    postFile,
    getStructure,
    getAllLanguages,
    getTranslations,
    postTranslation, 
    delTranslation,
    deleteTransport,
    getTransport,
    postTransport
}
