import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import languageSaga from './language/saga';
import flowSaga from './flow/saga';
import gogoSaga from './gogo/saga';
import uploadSaga from './upload/saga';
import adminSaga from './admin/saga';


export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    languageSaga(),
    flowSaga(),
    gogoSaga(),
    uploadSaga(),
    adminSaga()
  ]);
}
