import {
    LOAD_TRANSPORT_TYPE_LOADING,
    LOAD_TRANSPORT_TYPE_SUCCESS,
    LOAD_TRANSPORT_TYPE_ERROR,
    CLEAR_TRANSPORT_DATA
} from '../actions';

const initialState = {
    transportData: {
        slug: '',
        name: '',
        class_name: '',
        auth_fields: {},
        configuration: {
            fields: {},
            settings: {}
        }
    }, transportDataLoading: false, error: '',
};

export default function reduxSagaReducer(state = initialState, action) {

    switch (action.type) {

        case LOAD_TRANSPORT_TYPE_LOADING: {

            return {
                ...state,
                transportDataLoading: true,
                error: ''
            };

        }

        case LOAD_TRANSPORT_TYPE_SUCCESS: {

            return {
                ...state,
                transportDataLoading: false,
                transportData: action.data,
            };

        }

        case LOAD_TRANSPORT_TYPE_ERROR: {

            return {
                ...state,
                transportDataLoading: false,
                error: action.error
            };

        }

        case CLEAR_TRANSPORT_DATA: {

            return {
                ...state,
                transportData: initialState.transportData,
                transportDataLoading: initialState.transportDataLoading,
                error: initialState.error
            };

        }

        default: {

            return state;

        }
    }
}