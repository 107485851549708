import { apiConfig } from "../constants/defaultValues";
import axios from 'axios';

const generateHeaders = () => {

    return  {
        headers: {'Authorization': 'Bearer ' + localStorage.getItem('user_token')}
    };
}

const fetchTransports = () => {
    const options = generateHeaders()
    return axios.get(`${apiConfig.url}transport`,  options)
};

const fetchFlows = () => {
    const options = generateHeaders()
    return axios.get(`${apiConfig.url}flow`,  options)
};

const fetchFlowById = (id) => {
    const options = generateHeaders()
    return axios.get(`${apiConfig.url}flow/${id}`,  options)
};

const postFlow = (data) => {

    const options = generateHeaders()

    if (data.id){
        return axios.put(`${apiConfig.url}flow`, data, options)
    }else {
        return axios.post(`${apiConfig.url}flow`, data, options)
    }

};

const fetchIdentityTransports = () => {
    const options = generateHeaders()
    return axios.get(`${apiConfig.url}transport-identity`,  options)
};

const deleteIdentityTransports = (id) => {
    const options = generateHeaders()
    return axios.delete(`${apiConfig.url}transport-identity/${id}`,  options)
};

const deleteFlow = (id) => {
    const options = generateHeaders()
    return axios.delete(`${apiConfig.url}flow/${id}`,  options)
};

const fetchIdentityTransportById = (id) => {
    const options = generateHeaders()
    return axios.get(`${apiConfig.url}transport-identity/${id}`,  options)
};

const fetchIdentityTransportHeaders = (id) => {
    const options = generateHeaders()
    return axios.get(`${apiConfig.url}transport-identity/headers/${id}`,  options)
};

const fetchIoTypes = () => {
    const options = generateHeaders()
    return axios.get(`${apiConfig.url}io-type`,  options)
};

const createTransport = (postData) => {
    const options = generateHeaders()
    if (postData.id){
        return axios.put(`${apiConfig.url}transport-identity`, postData, options)
    }else {
        return axios.post(`${apiConfig.url}transport-identity`, postData, options)
    }
};

const testTransportValid = (postData) => {

    const options = generateHeaders()
    return axios.post(`${apiConfig.url}transport-identity/connect`, postData, options)
};

/*axios.interceptors.request.use(function (config) {

    const token = localStorage.getItem('user_token');
    config.headers.Authorization =  'Bearer '+token;

    return config;
});*/

export {
    fetchTransports,
    fetchIoTypes,
    createTransport,
    testTransportValid,
    fetchIdentityTransports,
    fetchIdentityTransportById,
    fetchIdentityTransportHeaders,
    deleteIdentityTransports,
    fetchFlows,
    postFlow,
    fetchFlowById,
    deleteFlow
}
