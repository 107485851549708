import {
    LOAD_COMPANY_DETAILS_LOADING, LOAD_TRANSPORT_ERROR,
    LOAD_COMPANY_DETAILS_SUCCESS,
    POST_COMPANY_DETAILS,
    POST_COMPANY_DETAILS_SUCCESS,
    POST_COMPANY_DETAILS_ERROR,
    CHECK_VAT_NUMBER,
    CHECK_VAT_NUMBER_SUCCESS,
    CHECK_VAT_NUMBER_ERROR,
    LOAD_ACCOUNT_DETAILS_LOADING,
    LOAD_ACCOUNT_DETAILS_SUCCESS,
    LOAD_ACCOUNT_DETAILS_ERROR,
    POST_ACCOUNT_DETAILS,
    POST_ACCOUNT_DETAILS_SUCCESS,
    POST_ACCOUNT_DETAILS_ERROR,
} from '../actions';

const initialState = {   data: {},   loading: true,   error: ''};

export default function reduxSagaReducer(state = initialState, action) {

    switch (action.type) {

        case LOAD_COMPANY_DETAILS_LOADING: {
            return {
                ...state,
                loading: true,
                error:''
            };
        }

        case LOAD_COMPANY_DETAILS_SUCCESS: {
            return {
                ...state,
                companyData: action.data,
                loading:false,
                editDetails: ( action.data != "" ) ? true : false
            };
        }

        case POST_COMPANY_DETAILS_SUCCESS: {
            return {
                ...state,
                data: action.data,
                loading: true,
            };
        }

        case POST_COMPANY_DETAILS_ERROR: {
            return {
                ...state,
                loading: false,
                error: action.error
            };

        }

        case POST_COMPANY_DETAILS: {
            return {
                ...state,
                data: action.data,
                loading: false
            }
        }

        case CHECK_VAT_NUMBER: {
            return {
                ...state,
                data: action.data,
                loading: false
            }
        }

        case CHECK_VAT_NUMBER_SUCCESS: {
            return {
                ...state,
                data: action.data,
                loading: true,
            };
        }

        case CHECK_VAT_NUMBER_ERROR: {
            return {
                ...state,
                loading: false,
                error: action.error
            };
        }

        case LOAD_ACCOUNT_DETAILS_LOADING: {
            return {
                ...state,
                accountData: action.data,
                loading: true,
                error:''
            };
        }
        case LOAD_ACCOUNT_DETAILS_SUCCESS: {
            return {
                ...state,
                accountData: action.data,
                loading:false,
            };
        }
        case LOAD_ACCOUNT_DETAILS_ERROR: {
            return {
                ...state,
                loading: false,
                error: action.error
            };
        }

        case POST_ACCOUNT_DETAILS: {
            return {
                ...state,
                data: action.data,
                loading: false
            }
        }

        case POST_ACCOUNT_DETAILS_SUCCESS: {
            return {
                ...state,
                data: action.data,
                loading: true,
            };
        }

        case POST_ACCOUNT_DETAILS_ERROR: {
            return {
                ...state,
                loading: false,
                error: action.error
            };

        }

        default: {
            return state;
        }

    }

}
